<template>
  <div>
    <b-row v-if="favorites.length > 0">
      <b-col
        v-for="(favorite, index) in favorites"
        :key="index"
        xs="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col cols="auto">
              <b-avatar
                v-if="!favorite.image"
                :text="favorite.avatar_text"
                variant="primary"
                size="48"
              />
              <b-avatar
                v-else
                :src="favorite.image"
                variant="primary"
                size="48"
              />
            </b-col>
            <b-col>
              <b-card-title class="mb-1">
                {{ favorite.title }}
              </b-card-title>
              <b-card-sub-title>{{ favorite.company }}</b-card-sub-title>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="flat-primary"
                class="btn-icon"
                @click="setFavorite(favorite.id, index)"
              >
                <FeatherIcon
                  icon="HeartIcon"
                  :class="favorite.favorite ? 'favoriteIcon' : ''"
                />
              </b-button>
            </b-col>
          </b-row>
          <div class="text-center">
            <ul class="list-inline">
              <li
                v-for="(focuses, fKey) in favorite.marketing_focus"
                :key="fKey"
                class="list-inline-item"
              >
                <b-badge
                  variant="light-success"
                >
                  {{ focuses }}
                </b-badge>
              </li>
            </ul>
          </div>
          <div>{{ favorite.description }}</div>
          <div class="text-center mt-1">
            <b-button
              variant="outline-primary"
              :to="'girisimler/' + favorite.seourl"
            >
              {{ $t('Görüntüle') }}
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div v-else>
      <b-alert
        variant="warning"
        show
        class="p-1"
      >
        <div class="alert-body text-center">
          <span class="d-block mb-2">{{ $t('Henüz favoriye eklediğiniz girişim yok.') }}</span>
          <b-button
            variant="primary"
            :to="'/girisimler'"
          >
            {{ $t('Girişimleri Keşfet') }}
          </b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BAvatar, BBadge, BButton, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BBadge,
    BButton,
    BAlert,
  },
  computed: {
    favorites() {
      return this.$store.getters['favorites/getFavorites']
    },
    favoriteToggleStatus() {
      return this.$store.getters['favorites/getFavoritesToggleStatus']
    },
  },
  watch: {
    favoriteToggleStatus(val) {
      if (val.message !== null) {
        this.makeToast(val)
        this.getFavorites()
      }
    },
  },
  created() {
    this.getFavorites()
  },
  methods: {
    getFavorites() {
      this.$store.dispatch('favorites/favoritesList')
    },
    setFavorite(id, index) {
      this.favorites[index].favorite = !this.favorites[index].favorite
      this.$store.dispatch('favorites/favoritesToggle', id)
    },
    makeToast(params) {
      let variant = 'success'
      if (!params.status) {
        variant = 'danger'
      }
      this.$bvToast.toast(params.message, {
        title: this.$t('İşlem Başarılı'),
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="sass">
.favoriteIcon
  fill: #FF7032
</style>
